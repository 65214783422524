.fileInput {
	width: 100%;
}

.content {
	border: 1px dashed var(--grayscale10);
	border-radius: 16px;
	box-sizing: border-box;
	padding: 20px 18px;
}

.preview {
	align-items: center;
	display: flex;
	height: 150px;
	justify-content: center;
	width: 100%;

	img {
		height: 100%;
		max-height: 150px;
		object-fit: contain;
		width: auto;
	}
}
