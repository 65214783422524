.header {
	height: auto;
	line-height: 1;
	padding: 10px 16px;
}

.sticky {
	position: sticky;
	top: 0;
	z-index: 999;
}
