.closeButtonWrapper {
	border-left: 1px solid #1a38601a;
	margin-left: 10px;
	padding-left: 20px;
}

.button {
	border: none;
	box-shadow: none;
}
