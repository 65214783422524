.icon {
	cursor: pointer;
	margin-left: 5px;
	opacity: 0;
	transition: opacity 0.2s;

	&:hover {
		color: #7448d0;
	}

	.label:hover & {
		opacity: 1;
	}
}
