@use "sass:map";
@use "variables";

:root {
	@each $name, $color in variables.$colors {
		--#{$name}: #{$color};
	}
}

body {
	background-color: var(--bodyBg);
	margin: 0;
}
