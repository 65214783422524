.wrap {
	align-items: flex-end;
	width: 100%;

	> * {
		flex: 1;
	}
}

.checkboxWrap {
	flex: 0 0 auto;
}
