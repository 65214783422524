.horizontal {
	:global .ant-form-item {
		&-row {
			align-items: center;
			flex-direction: row;
			flex-wrap: nowrap;
		}

		&-label {
			flex: 0 0 auto;
			padding: 0;
			white-space: nowrap;
		}

		&-control {
			flex: 1 1 auto;
		}
	}
}
