.selectContainer {
	flex: 2;
}

.dateContainer {
	flex: 1;
}

.dateInput {
	width: 100%;
}
